import React, { useContext } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory, NavLink } from 'react-router-dom'; // Import useHistory
import { useSelector } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import PasswordField from '@/shared/components/form/Password';
import {
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Toaster } from 'react-hot-toast';
import renderCheckBoxField from '../../form/CheckBox';
import {
  AccountButton, AccountButtons, AccountForgotPassword, LoginForm,
} from '../AccountElements';
import AuthSign from '../../../../utils/AuthSignIn'; // Import AuthSign
import { AuthContext } from '../../../../context/AuthContext';


const LogInForm = ({
 errorMessage, fieldUser, typeFieldUser, form, 
}) => {
  const errorMsg = useSelector(state => state.user.error);
  const history = useHistory(); // Use useHistory hook to get the history object
  const { setIsLoggedIn, setUsername } = useContext(AuthContext);
  const handleSubmits = async (values) => {
    // Pass values and history to AuthSign
    await AuthSign(values, history, setIsLoggedIn, setUsername);
  };
  
  return (
    <Form onSubmit={handleSubmits}>
      {({ handleSubmit }) => (
        <LoginForm onSubmit={handleSubmit}>
          <Alert variant="danger" show={!!errorMessage || !!errorMsg}>
            {errorMessage}
            {errorMsg}
          </Alert>
          <FormGroup>
            <FormGroupLabel>{fieldUser}</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <AccountOutlineIcon />
              </FormGroupIcon>
              <Field
                name="username"
                component="input"
                type={typeFieldUser}
                placeholder={fieldUser}
                className="input-without-border-radius"
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Password</FormGroupLabel>
            <FormGroupField>
              <Field
                name="passwords"
                component={PasswordField}
                placeholder="Password"
                className="input-without-border-radius"
                keyIcon
              />
              <AccountForgotPassword>
                <NavLink to="/reset_password">Forgot a password?</NavLink>
              </AccountForgotPassword>
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupField>
              <Field
                name="remember_me"
                render={renderCheckBoxField}
                label="Remember me"
                type="checkbox"
              />
            </FormGroupField>
          </FormGroup>
          <AccountButtons>
            <AccountButton type="submit" variant="primary">
              Sign In
            </AccountButton>
          </AccountButtons>
        </LoginForm>
      )}
    </Form>
  );
};

LogInForm.propTypes = {
  errorMessage: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
};

LogInForm.defaultProps = {
  errorMessage: '',
  fieldUser: 'Username',
  typeFieldUser: 'text',
};

export default LogInForm;
