import React, { useState, useContext, useEffect } from 'react';
import {
   Nav, Tab, Modal,
} from 'react-bootstrap';

import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';
import { FaPlus } from 'react-icons/fa';
import { Button } from '@/shared/components/Button';
import toast from 'react-hot-toast';
import Search from './Search';

import AddMeal from './AddMeal';
import { Mealcontext } from '../../../../context/MealContext';
import MealDetails from './MealDetails';

const Tabs = () => {
  const { 
    setRefresh, 
    isDetailsClick, 
    click, 
    setclick,
    activeKey, 
    setActiveKey,
  } = useContext(Mealcontext);
  const [showModal, setShowModal] = useState(false);
  // const [activeKey, setActiveKey] = useState('1');
  // const [click, setclick] = useState('searchSelect');
  // isDetailsClick is true
  useEffect(() => {
    if (isDetailsClick) {
      setActiveKey('2');
      setclick('detailsClick');
      // console.log(activeKey); 
    }
  }, [isDetailsClick]);

  const AddMeals = async (values) => {
    // console.log(JSON.stringify(values.restaurantid.value));
    const formattedValues = {
        items: values.meals,
    };

    const sdate = new Date(values.startDate);
    const startDate = sdate.toISOString().split('T')[0];
    const edate = new Date(values.endDate);
    const endDate = edate.toISOString().split('T')[0];
      

    const mealOffered = [];

    if (values.breakfast) {
        mealOffered.push('1');
    }
    if (values.snacks) {
        mealOffered.push('2');
    }
    if (values.lunch) {
        mealOffered.push('3');
    }
    if (values.dinner) {
        mealOffered.push('4');
    }

    const formData = new FormData();
    formData.append('name', values.mealName);
    formData.append('restaurant_id', values.restaurantid.value);  
    formData.append('price', values.mealPrice); 
    formData.append('foc', values.foc); 
    formData.append('foc_on', values.focon); 
    formData.append('meal_type', values.mealType); 
    formData.append('discription', values.discription); 
     formData.append('photo', values.logo); 
    formData.append('start_date', startDate); 
    formData.append('end_date', endDate); 
    formData.append('markup_percentage', values.markup);
    formData.append('cuisine', values.cuisines); 
    formData.append('defaultMarkup', values.markup); 
    formData.append('meal_offered', mealOffered.join(',')); 
    formData.append('meal_format', values.format); 
    formData.append('veg_non_veg', values.veg); 
    // formData.append('veg_non_veg', values.veg); 


    if (Array.isArray(formattedValues.items)) {
        formData.append('items', JSON.stringify(formattedValues)); 
    } else {
        console.error('Items format is invalid');
    }

  //   formData.forEach((value, key) => {
  //     console.log(`${key}: ${value}`);
  // });


    try {
        const response = await fetch('https://navrang.mvd-tech.io/API/meal_add.php', {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            console.log(`Network response was not ok: ${response.statusText}`);
        }
        const responseData = await response.json();
        if (responseData.status && responseData.message === 'Data inserted successfully.') {
            toast.success('Meal Added Successfully...');
            setRefresh(prev => !prev);
            setShowModal(false);
        } else {
            toast.error(responseData.message);
        }
    } catch (error) {
        toast.error(error.message);
    }    
};



 
  

  return (
    <>
      {/* Tabs */}
      <Tab.Container activeKey={activeKey} onSelect={key => setActiveKey(key)}>
        <TabsWrap>
          <Button 
            variant="outline-primary" 
            size="sm" 
            onClick={() => setShowModal(true)}
          >
            <span>Add Meal</span><FaPlus />
          </Button>
          
          {/* <Button variant="primary" onClick={() => setShowModal(true)} style={{ marginTop: '20px' }}>
            Add Agent
          </Button> */}
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink eventKey="1" disabled={isDetailsClick}>Search</NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="2" disabled={!isDetailsClick}>Details</NavLink>
            </NavItem>
            
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="1">
              {click === 'searchSelect' && <Search />}
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              {click === 'detailsClick' && <MealDetails setActiveKey={setActiveKey} />}
            </Tab.Pane>
          </Tab.Content>
        </TabsWrap>
      </Tab.Container>

      {/* Modal for Adding Agent */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Meal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddMeal onSubmit={AddMeals} />
        </Modal.Body>
        <Modal.Footer>
          {/* Optional Close button can be added if needed */}
          {/* <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tabs;
