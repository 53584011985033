import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { MdDateRange } from 'react-icons/md';

import { Card } from '@/shared/components/Card';
import { CgProfile } from 'react-icons/cg';
import { Button } from '@/shared/components/Button';
import toast from 'react-hot-toast';
import {
  MobileAppWidgetLine,
  MobileAppWidgetStat,
  MobileAppWidgetTitle,
  MobileWidgetIconDown,
} from '../../../Dashboards/App/MobileWidgetDashboardElements';
import { RestaurantContext } from '../../../../context/RestaurantContext';
import { DashboardWidgetCard } from '../../../Dashboards/BasicDashboardComponents';

const RequestCard = ({
  index, 
  name, 
  id, 
  date,
  logo,
}) => {
  const path = 'https://navrang.mvd-tech.io/API/images/restaurant/';
  const [selectedImage, setSelectedImage] = useState(path + logo);
  const { setStatusChanged, setRefresh } = useContext(RestaurantContext);

  // Accept action
  const handleAccept = async (ids) => {
    try {
      const response = await fetch(
        `https://navrang.mvd-tech.io/API/restaurant_confirmation.php?id=${ids}&isApproved=1`, 
        { method: 'GET' },
      );
      const result = await response.json();

      if (result.status && result.message === 'Success') {
        setStatusChanged(true);
        setRefresh(prev => !prev);
        toast.success('Restaurant confirmed!');
      } else {
        setStatusChanged(false);
        toast.error(result.message || 'Failed to confirm');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  // Reject action
  const handleReject = async (ids) => {
    try {
      const response = await fetch(
        `https://navrang.mvd-tech.io/API/restaurant_confirmation.php?id=${ids}&isApproved=2`, 
        { method: 'GET' },
      );
      const result = await response.json();

      if (result.status && result.message === 'Success') {
        setStatusChanged(true);
        toast.success('Restaurant rejected!');
      } else {
        setStatusChanged(false);
        toast.error(result.message || 'Failed to reject');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
    <Col 
      md={12} 
      xl={3} 
      lg={6} 
      xs={12} 
      className="m-4" 
      style={{
        border: '2px solid darkgrey', 
        borderRadius: '8px', 
        padding: '5px',
        height: '250px',
        width: '237px',
      }}
    >
      <Card>
        <DashboardWidgetCard>
          <div>
            <MobileAppWidgetLine>
              <MobileAppWidgetStat color="#ff4861">#{index + 1}</MobileAppWidgetStat>
              <img
                src={selectedImage}
                alt={name}
                style={{
                  borderRadius: '50%',
                  width: '70px',
                  height: '70px',
                  objectFit: 'cover',
                }}
              />
            </MobileAppWidgetLine>
            <MobileAppWidgetTitle>
              <h5 className="mt-2"><CgProfile />{' '}{name}</h5>
              <h5 className="mt-2"><MdDateRange />{' '}{date}</h5>
            </MobileAppWidgetTitle>
            <div className="d-flex mt-4">
              <Button
                size="sm"
                variant="outline-success"
                onClick={() => handleAccept(id)}
              >
                Confirm
              </Button>
              <Button
                size="sm"
                className="mx-2"
                variant="outline-danger"
                onClick={() => handleReject(id)}
              >
                Reject
              </Button>
            </div>
          </div>
        </DashboardWidgetCard>
      </Card>
    </Col>
  );
};

// PropTypes validation
RequestCard.propTypes = {
  index: PropTypes.number.isRequired, 
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired, 
  date: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default RequestCard;
