import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import renderTimePickerField from '@/shared/components/form/date-pickers/TimePicker';
import { useSelector } from 'react-redux';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
    FormHalfContainer,
  } from '@/shared/components/form/FormElements';
  import renderCheckBoxField from '@/shared/components/form/CheckBox';
  import { Button } from '@/shared/components/Button';
  import PropTypes from 'prop-types';
import renderRadioButtonField from '@/shared/components/form/RadioButton';


const AddRestaurant = ({ onSubmit }) => { 
    const themeName = useSelector(state => state.theme.className);
 const [selectedImage, setSelectedImage] = useState(null);
    // handle file
    const handleFileChange = (event, input) => {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        input.onChange(file);
      } else {
        setSelectedImage(null);
        input.onChange(null);
      }
    };
    
 
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle />
          </CardTitleWrap>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Restaurant Name</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="restaurantName"
                        component="input"
                        type="text"
                        placeholder="Restaurant Name"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 1</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="address1"
                        component="input"
                        type="text"
                        placeholder="Address Line 1"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Area</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="area"
                        component="input"
                        type="text"
                        placeholder="Area"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>State</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="state"
                        component="input"
                        type="text"
                        placeholder="State"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Pincode</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="pincode"
                        component="input"
                        type="numbe"
                        placeholder="Pincode"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Discription</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="discription"
                        component="textarea"
                        type="text"
                        placeholder="Discription"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Setting Capacity</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="settingCap"
                        component="input"
                        type="number"
                        placeholder="Setting Capacity"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal Offered</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="breakfast"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Breakfast"
                      />
                      <Field
                        name="snacks"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Snacks"
                      />
                      <Field
                        name="lunch"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Lunch"
                      />
                      <Field
                        name="dinner"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Dinner"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal format</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="fit"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Fit"
                      />
                      <Field
                        name="git"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Git"
                      />
                      <Field
                        name="delivery"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Delivery"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel style={{ color: 'red' }}>Capping Capacity</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="capCapacity"
                        component="input"
                        type="number"
                        placeholder="Capping Capacity"
                      />
                    </FormGroupField>
                  </FormGroup>
                </FormHalfContainer>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>UIN</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="uin"
                        component="input"
                        type="number"
                        placeholder="UIN"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 2</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="address2"
                        component="input"
                        type="text"
                        placeholder="Address Line 2"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>City</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="city"
                        component="input"
                        type="text"
                        placeholder="City"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Country</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="country"
                        component="input"
                        type="text"
                        placeholder="Country"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Location on Map</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="location"
                        component="input"
                        type="text"
                        placeholder="Location on Map"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Logo</FormGroupLabel>
                    <FormGroupField>
                      <Field 
                        name="logo"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handleFileChange(e, input)}
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>

                  {selectedImage && (
                    <FormGroup>
                      <FormGroupLabel>Selected Logo</FormGroupLabel>
                      <FormGroupField>
                        <img
                          src={selectedImage}
                          alt="Selected Logo"
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                      </FormGroupField>
                    </FormGroup>
                  )}

                  <FormGroup>
                    <FormGroupLabel>Cuisines</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="cuisines"
                        component="input"
                        type="text"
                        placeholder="Cuisines"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Tax identification #</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="taxID"
                        component="input"
                        type="text"
                        placeholder="Tax identification"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Timing</FormGroupLabel>
                    <FormGroupField style={{ display: 'flex' }}>
                      <FormGroupLabel className="mt-2 mx-2">From</FormGroupLabel>
                      <Field
                        name="Starttime"
                        component={renderTimePickerField}
                        theme={themeName}
                        className="w-40"
                      />
                      <FormGroupLabel className="mt-2 mx-2">To</FormGroupLabel>
                      <Field
                        name="Endtime"
                        component={renderTimePickerField}
                        theme={themeName}
                        className="w-40"
                        style={{ marginLeft: '10px' }} 

                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel style={{ color: 'red' }}>Markup Percentage</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="markup"
                        component="input"
                        type="number"
                        placeholder="Markup Percentage"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormButtonToolbar>
                    <Button variant="primary" type="submit">Submit</Button>
                    <Button variant="secondary" type="button">
                      Reset
                    </Button>
                  </FormButtonToolbar>
                </FormHalfContainer>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
    
  );
};
AddRestaurant.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
export default AddRestaurant;
