import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import renderTimePickerField from '@/shared/components/form/date-pickers/TimePicker';
import { useSelector } from 'react-redux';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import { Button } from '@/shared/components/Button';
import PropTypes from 'prop-types';

const EditRestaurantData = ({ onSubmit, id }) => {
  const themeName = useSelector(state => state.theme.className);
  const [selectedImage, setSelectedImage] = useState(null);
  const [restaurantData, setRestaurantData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // For loading state

  // Fetch restaurant data
  const fetchRestaurantData = async () => {
    try {
      const response = await fetch(`https://navrang.mvd-tech.io/API/restaurant_data_fetch.php?id=${id}`);
      const data = await response.json();
      if (data.status && data.message === 'Success' && data.data?.length) {
        setRestaurantData(data.data[0]);
        setSelectedImage(`https://navrang.mvd-tech.io/API/images/restaurant/${data.data[0].photo}`);
      } else {
        console.log('Error fetching data');
        setRestaurantData(null);
      }
    } catch (e) {
      console.log(`Error fetching data: ${e.message}`);
      setRestaurantData(null);
    } finally {
      setIsLoading(false); // Set loading to false when the fetch is done
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true); // Show loading state while fetching
      fetchRestaurantData();
    }
  }, [id]);

  // Handle file input for image
  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      input.onChange(file);
    } else {
      setSelectedImage(null);
      input.onChange(null);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Show loading spinner or message while data is being fetched
  } 

  // Corrected function to check for meal offering based on the type
const getMealOfferedChecked = (mealOffered, type) => {
    const mealMap = {
      breakfast: 1,
      snacks: 2,
      lunch: 3,
      dinner: 4,
    };
  
    // If mealOffered is an array or object, check if the type is included
    return mealOffered && mealOffered.includes(mealMap[type]);
  };
  
  // Corrected function to check for meal format based on the type
  const getMealFormatChecked = (mealFormat, type) => {
    const mealMap = {
      fit: 1,
      git: 2,
      delivery: 3,
    };
  
    // If mealFormat is an array or object, check if the type is included
    return mealFormat && mealFormat.includes(mealMap[type]);
  };
  

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Form
            key={restaurantData?.id} // This ensures the form is reset when restaurantData changes
            onSubmit={onSubmit}
            initialValues={restaurantData}
          >
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit} initialValues={restaurantData}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Restaurant Name</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="id"
                        component="input"
                        type="number"
                        placeholder="UID"
                        style={{ display: 'none' }}
                      />
                      <Field
                        name="name"
                        component="input"
                        type="text"
                        placeholder="Restaurant Name"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 1</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="address1"
                        component="input"
                        type="text"
                        placeholder="Address Line 1"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Area</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="area"
                        component="input"
                        type="text"
                        placeholder="Area"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>State</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="state"
                        component="input"
                        type="text"
                        placeholder="State"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Pincode</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="pincode"
                        component="input"
                        type="number"
                        placeholder="Pincode"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Description</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="description"
                        component="textarea"
                        placeholder="Description"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Setting Capacity</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="setting_capacity"
                        component="input"
                        type="number"
                        placeholder="Setting Capacity"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal Offered</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="breakfast"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Breakfast"
                        initialValue={getMealOfferedChecked(restaurantData.meal_offered, 'breakfast')}
                      />
                      <Field
                        name="snacks"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Snacks"
                        initialValue={getMealOfferedChecked(restaurantData.meal_offered, 'snacks')}
                      />
                      <Field
                        name="lunch"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Lunch"
                        initialValue={getMealOfferedChecked(restaurantData.meal_offered, 'lunch')}
                      />
                      <Field
                        name="dinner"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Dinner"
                        initialValue={getMealOfferedChecked(restaurantData.meal_offered, 'dinner')}
                      />
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>Meal Format</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="fit"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Fit"
                        initialValue={getMealFormatChecked(restaurantData.meal_format, 'fit')}
                      />
                      <Field
                        name="git"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Git"
                        initialValue={getMealFormatChecked(restaurantData.meal_format, 'git')}
                      />
                      <Field
                        name="delivery"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Delivery"
                        initialValue={getMealFormatChecked(restaurantData.meal_format, 'delivery')}
                      />
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel style={{ color: 'red' }}>Capping Capacity</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="capping_capacity"
                        component="input"
                        type="number"
                        placeholder="Capping Capacity"
                      />
                    </FormGroupField>
                  </FormGroup>
                </FormHalfContainer>

                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>UIN</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="uin"
                        component="input"
                        type="number"
                        placeholder="UIN"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 2</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="address2"
                        component="input"
                        type="text"
                        placeholder="Address Line 2"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>City</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="city"
                        component="input"
                        type="text"
                        placeholder="City"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Country</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="country"
                        component="input"
                        type="text"
                        placeholder="Country"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Location on Map</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="location"
                        component="input"
                        type="text"
                        placeholder="Location on Map"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Logo</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="logo"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handleFileChange(e, input)}
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>

                  {selectedImage && (
                    <FormGroup>
                      <FormGroupLabel>Selected Logo</FormGroupLabel>
                      <FormGroupField>
                        <img
                          src={selectedImage}
                          alt="Selected Logo"
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                      </FormGroupField>
                    </FormGroup>
                  )}

                  <FormGroup>
                    <FormGroupLabel>Cuisines</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="cuisines"
                        component="input"
                        type="text"
                        placeholder="Cuisines"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Tax Identification #</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="tax_id"
                        component="input"
                        type="number"
                        placeholder="Tax Identification"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Opening Time</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="start_time"
                        component={renderTimePickerField}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Closing Time</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="end_time"
                        component={renderTimePickerField}
                      />
                    </FormGroupField>
                  </FormGroup>
                </FormHalfContainer>
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                  <Button variant="secondary" type="button">
                    Reset
                  </Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

EditRestaurantData.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default EditRestaurantData;
