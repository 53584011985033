import React, { useState, useEffect, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { CgDetailsMore, CgNotes } from 'react-icons/cg';
import { IoMdTime } from 'react-icons/io';
import { MdOutlineLunchDining, MdOutlineArticle, MdRestaurantMenu } from 'react-icons/md';
import { FaPeopleLine, FaMapLocationDot } from 'react-icons/fa6';
import { Button } from '@/shared/components/Button';
import MealCardForDetails from './MealCardForDetails';
import { Mealcontext } from '../../../../context/MealContext';

const MealDetails = () => {
  const [mealData, setMealData] = useState([]);
  const [restaurantData, setRestaurantData] = useState(null);
  const [similarMeals, setSimilarMeals] = useState([]);
  const { 
    resaurantDetailsId,
     refresh, 
     setclick,
     setActiveKey,
     setIsDetailsClick,
     } = useContext(Mealcontext);
  const [resId, setResId] = useState(null);

  // Fetch meal data
  useEffect(() => {
    const fetchMeal = async () => {
      if (!resaurantDetailsId) {
         toast.error('resisnotfound');
         return;
       }

      try {
        const url = `https://navrang.mvd-tech.io/API/meal_data_fetch.php?id=${resaurantDetailsId}`;
        const response = await fetch(url);
        const data = await response.json();

        if (data.status && data.message === 'Success') {
          setMealData(data.data[0]);
          setResId(data.data[0].restaurant_id);
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    fetchMeal();
  }, [resaurantDetailsId, refresh]);
  
  console.log('mealdata');
  console.log(JSON.stringify(mealData));
  console.log('resid');
  console.log(resId);
  useEffect(() => {
    if (mealData && mealData.restaurant_id) {
      const fetchRestaurantData = async () => {
        try {
          const url = `https://navrang.mvd-tech.io/API/restaurant_data_fetch.php?id=${mealData.restaurant_id}`;
          const response = await fetch(url);
          const data = await response.json();

          if (data.status && data.message === 'Success') {
            setRestaurantData(data.data[0]);
          }
        } catch (e) {
          console.log(e.message);
        }
      };

      fetchRestaurantData();
    }
  }, [mealData]);

  // Fetch similar meals data when restaurant ID is available
  useEffect(() => {
    if (!resId) return;

    const fetchSimilarMeal = async () => {
      try {
        const response = await fetch(`https://navrang.mvd-tech.io/API/meal_restaurant_fetch.php?
          restaurant_id=${resId}&id=${resaurantDetailsId}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
         setSimilarMeals(data.data || []);
      } catch (e) {
        console.log(`Error: ${e.message}`);
      }
    };

    fetchSimilarMeal();
  }, [resId, resaurantDetailsId]);

  // Return loading state until both mealData and restaurantData are available
  if (!mealData || !restaurantData) {
    return <div>Loading...</div>;
  }

  const backHandler = () => {
    setActiveKey('1');
    setclick('searchSelect');
    setIsDetailsClick(false);
  };


  return (
    <Container>
      <div style={{ float: 'right' }}>
        <Button variant="primary" onClick={backHandler}>Back</Button>
      </div>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-4">
                  <h1>{mealData.name}</h1>
                </div>
                <div className="col-md-4">
                  <div className="price d-flex">
                    
                    <div className="card-body price d-flex align-items-center">
                      <h2 className="card-text me-2" style={{ color: 'red' }}>
                        {mealData.price} <h3 className="d-inline" style={{ fontSize: '0.75em' }}>AED</h3>
                      </h2>
                      <h2 className="card-text me-2" style={{ color: 'blue' }}>
                        {mealData.final_price} <h3 className="d-inline" style={{ fontSize: '0.75em' }}>AED</h3>
                      </h2>
                    </div>

                  </div>
                </div>
                
              </div>
              <div className="row mt-2">
                <h5>
                  <CgDetailsMore />{' '}{mealData.description}
                </h5>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <p><IoMdTime /> : {restaurantData.start_time}/{restaurantData.end_time}</p>
                </div>
                <div className="col-md-4">
                  <p><MdOutlineLunchDining /> : {restaurantData.cuisines}</p>
                </div>
                <div className="col-md-4">
                  <p><MdOutlineArticle /> :{mealData.meal_type}</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <p><MdRestaurantMenu /> : {restaurantData.meal_format}</p>
                </div>
                <div className="col-md-4">
                  <p><FaPeopleLine /> : {restaurantData.setting_capacity}/{restaurantData.setting_capacity}</p>
                </div>
                <div className="col-md-4">
                  <p><FaMapLocationDot /> : {restaurantData.location}</p>
                </div>
              </div>
              <div className="mealitems mt-2">
                <h5><CgNotes />Meal Items</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure, sint.</p>
                {/* <CgDetailsMore />{' '}{restaurantData.description} */}
              </div>
              <div className="row mt-4">
                <h2>Restaurant-{restaurantData.name}</h2>
                
              </div>
              <div className="row mt-2">
                <p><CgDetailsMore />{' '}{restaurantData.description}</p>
                
              </div>
              <div className="row mt-4">
                <h2>Similar Meals</h2>
              </div>

              <div className="similarMeals">
                {similarMeals.map((meal, index) => (
                  <MealCardForDetails
                    key={meal.id}
                    id={meal.id}
                    mealname={meal.name}
                    restaurantid={meal.restaurant_id}
                    description={meal.description}
                    type={meal.meal_type}
                    price={meal.price}
                    finalprice={meal.final_price}
                    format={meal.meal_format}
                    image={meal.photo}
                    isVeg={meal.veg_non_veg}
                    mealOffereds={meal.meal_offered}
                    cuisine={meal.cuisines}
                  />
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
);
};


// MealDetails.prototype = {
//   setActiveKey: PropTypes.func.isRequired,
// };

export default MealDetails;
