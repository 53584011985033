import React from 'react';
import PropTypes from 'prop-types'; 
import AuthContextProvider from './AuthContext'; 
import AgentContextProvider from './AgentContext';
import MealContextProvider from './MealContext';
import RestaurantContextProvider from './RestaurantContext';

const AppContextProvider = ({ children }) => (
  <AuthContextProvider>
    <AgentContextProvider>
      <MealContextProvider>
        <RestaurantContextProvider>
          {children}  
        </RestaurantContextProvider>
      </MealContextProvider>
    </AgentContextProvider>
  </AuthContextProvider>
  );

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired, 
};

export default AppContextProvider;
