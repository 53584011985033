import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'; // Import Redirect if needed
import MainWrapper from '../MainWrapper';
import Landing from '../../Landing/index';
import NotFound404 from '../../DefaultPage/404/index';
import LockScreen from '../../Account/LockScreen/index';
import LogIn from '../../Account/LogIn/index';
import LogInPhoto from '../../Account/LogInPhoto/index';
import Register from '../../Account/Register/index';
import RegisterPhoto from '../../Account/RegisterPhoto/index';
import ResetPassword from '../../Account/ResetPassword/index';
import ResetPasswordPhoto from '../../Account/ResetPasswordPhoto';
import WrappedRoutes from './WrappedRoutes';
import { AuthContext } from '../../../context/AuthContext';
import LogInForm from '../../../shared/components/account/loginForm/LogInForm';
import Mail from '../../Mail';
import Agent from '../../Agent';
import AppDashboard from '../../Dashboards/App';


const Router = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext); 
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await fetch('https://navrang.mvd-tech.io/API/auth.php');
        const data = await response.json();
        console.log(`isLogin:${data.loggedIn}`);
        setIsLoggedIn(data.loggedIn); 
      } catch (error) {
        console.error('Error checking login status:', error);
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, []); 

  console.log(isLoggedIn);
  return (
    <MainWrapper>
      <main>
        <Switch>
          {/* <Route exact path="/login" component={isLoggedIn ? LogIn : Landing} />
          <Route path="/landing" component={isLoggedIn ? LogIn : Landing} />
          <Route path="/404" component={isLoggedIn ? LogIn : Landing} />
          <Route path="*" component={isLoggedIn ? LogIn : Landing} />
          <Route path="/lock_screen" component={LockScreen} />
          <Route path="/log_in" component={LogIn} />
          <Route path="/log_in_photo" component={LogInPhoto} />
          <Route path="/register" component={Register} />
          <Route path="/register_photo" component={RegisterPhoto} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/reset_password_photo" component={ResetPasswordPhoto} />
          <Route path="/" component={WrappedRoutes} /> */}
          <Route exact path="/" component={isLoggedIn ? AppDashboard : LogIn} />
          {/* <Route path="/agent" component={Mail} /> */}
          {/* <Route path="/404" component={NotFound404} />
          <Route path="/lock_screen" component={LockScreen} />
          <Route path="/log_in" component={LogIn} />
          <Route path="/log_in_photo" component={LogInPhoto} />
          <Route path="/register" component={Register} />
          <Route path="/register_photo" component={RegisterPhoto} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/reset_password_photo" component={ResetPasswordPhoto} /> */}
          <Route path="/" component={isLoggedIn ? WrappedRoutes : LogIn} />
        </Switch>
      </main>
    </MainWrapper>
 );
};

export default Router;
