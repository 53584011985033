// import React from 'react';
// import PropTypes from 'prop-types';
// import { CiLogin } from 'react-icons/ci';
// import TopbarLanguage from '../components/topbar/TopbarLanguage';
// import TopbarMail from '../components/topbar/TopbarMail';
// import TopbarProfile from '../components/topbar/TopbarProfile';
// import TopbarNotification from '../components/topbar/TopbarNotification';
// import TopbarSearch from '../components/topbar/TopbarSearch';
// import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
// import {
//   TopbarContainer,
//   TopbarLeft,
//   TopbarLogo,
//   TopbarRight,
//   TopbarRightOver,
//   TopbarSearchWrap,
// } from '../components/topbar/BasicTopbarComponents';
// import TopbarWallet from '../components/topbar/TopbarWallet';

// const Topbar = ({
//   changeMobileSidebarVisibility,
//   changeSidebarVisibility,
// }) => (
//   <TopbarContainer>
//     <TopbarLeft>
//       <TopbarSidebarButton
//         onClickMobile={changeMobileSidebarVisibility}
//         onClickDesktop={changeSidebarVisibility}
//       />
//       <TopbarLogo to="/online_marketing_dashboard" />
//     </TopbarLeft>
//     <TopbarRight>
//       <TopbarSearchWrap>
//         <TopbarSearch />
//       </TopbarSearchWrap>
//       Add toggle button
//       <TopbarRightOver>
//         <TopbarNotification />
//         <TopbarMail new />
//         <TopbarProfile />
         
//         <TopbarLanguage />
//         <TopbarWallet />
//         <CiLogin />
//       </TopbarRightOver>
//     </TopbarRight>
//   </TopbarContainer>
//   );

// Topbar.propTypes = {
//   changeMobileSidebarVisibility: PropTypes.func.isRequired,
//   changeSidebarVisibility: PropTypes.func.isRequired,
// };

// export default Topbar;

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { CiLogin } from 'react-icons/ci';
import { useDispatch } from 'react-redux'; // Import useDispatch from react-redux
import { changeThemeToDark, changeThemeToLight } from '@/redux/actions/themeActions'; // Import the actions
import { Link, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

import TopbarLanguage from '../components/topbar/TopbarLanguage';
import TopbarMail from '../components/topbar/TopbarMail';
import TopbarProfile from '../components/topbar/TopbarProfile';
import TopbarNotification from '../components/topbar/TopbarNotification';
import TopbarSearch from '../components/topbar/TopbarSearch';
import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
  TopbarSearchWrap,
} from '../components/topbar/BasicTopbarComponents';
import TopbarWallet from '../components/topbar/TopbarWallet';
import { AuthContext } from '../../../context/AuthContext';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const [isToggled, setIsToggled] = useState(false);
  const { setIsLoggedIn } = useContext(AuthContext);
   const history = useHistory();

  // Dispatch hook from react-redux
  const dispatch = useDispatch();

  const handleToggle = () => {
    setIsToggled(!isToggled);

    // Directly dispatch the theme change action for immediate effect
    if (!isToggled) {
      dispatch(changeThemeToLight());
    } else {
      dispatch(changeThemeToDark());
    }
  };
     
  const onlogoutHandler = async () => {
     const url = await fetch('https://navrang.mvd-tech.io/API/logout.php');
     const response = await url.json();
     if (response.loggedOut === true) {
       toast.success('Logout Successfull Redirecting....');
       setIsLoggedIn(false);
       setTimeout(() => {
         history.push('/');
       }, 2000);
     } else {
      toast.error('Something Went Wrong....');
     }
  };

  return (
    <TopbarContainer>
      <TopbarLeft>
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />
        <TopbarLogo to="/online_marketing_dashboard" />
      </TopbarLeft>
      <TopbarRight>
        {/* <TopbarSearchWrap>
          <TopbarSearch />
        </TopbarSearchWrap> */}

        <button
          type="button"
          onClick={handleToggle}
          style={{
            background: isToggled
              ? 'linear-gradient(145deg, #6e7dff, #4757d8)' // Dark mode gradient
              : 'linear-gradient(145deg, #ffaf7b, #ff6a3d)', // Light mode gradient
            color: 'white',
            fontSize: '14px', // Smaller font size
            fontWeight: 'bold',
            padding: '8px 16px', // Smaller padding
            marginTop: '15px',
            border: 'none',
            borderRadius: '25px', // Rounded corners for the button
            cursor: 'pointer',
            position: 'relative',
            overflow: 'hidden', // Ensure slider stays inside the button
            width: '70px', // Small button width
            height: '30px', // Small button height
            transition: 'all 0.2s ease', // Faster transition (0.2s)
            willChange: 'background, transform', // Optimize for smoother transitions
          }}
          onMouseEnter={(e) => {
            e.target.style.boxShadow = '4px 4px 16px rgba(0, 0, 0, 0.2)';
          }}
          onMouseLeave={(e) => {
            e.target.style.boxShadow = '2px 2px 8px rgba(0, 0, 0, 0.1)';
          }}
        >
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: isToggled ? '50%' : '0%',
              transform: 'translateY(-50%)',
              width: '50%',
              height: '100%',
              backgroundColor: 'white',
              borderRadius: '50%',
              transition: 'all 0.1s ease', // Faster slider transition
            }}
          />
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
              zIndex: 1,
              opacity: isToggled ? 0 : 1, // Fade effect for text change
              transition: 'opacity 0.1s ease', // Faster opacity transition
            }}
          >
            🌞 {/* Sun emoji for light */}
          </span>
          <span
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              zIndex: 1,
              opacity: isToggled ? 1 : 0, // Fade effect for text change
              transition: 'opacity 0.2s ease', // Faster opacity transition
            }}
          >
            🌙 {/* Moon emoji for dark */}
          </span>
        </button>

        <TopbarRightOver
          style={{
            display: 'flex', // Use flexbox for alignment
            justifyContent: 'flex-end', // Align items to the right
            alignItems: 'center', // Center items vertically
            gap: '10px', // Space between elements
          }}
        >
          {/* <TopbarNotification />
          <TopbarMail new /> */}
          <TopbarProfile />
          {/* <TopbarLanguage />
          <TopbarWallet /> */}
          <button
            type="button"
            onClick={onlogoutHandler}
            style={{
              background: 'transparent', // No background for button
              border: 'none',
              padding: '5px', // Add padding around the icon for click area
              cursor: 'pointer', // Ensure it's clickable
            }}
            aria-label="Logout" // Add accessibility label
          >
            <CiLogin
              style={{
                fontSize: '24px',
                transition: 'transform 0.3s ease', // Optional hover effect
              }}
            />
          </button>
          
        </TopbarRightOver>
      </TopbarRight>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;

