// AuthSign.js
import React, { useContext } from 'react';
import toast from 'react-hot-toast';


const AuthSign = async (values, history, setIsLoggedIn, setUsername) => {
  const { username, passwords } = values;


  const loginData = new URLSearchParams({
    email: username,
    password: passwords,
  }).toString();

  try {
   const response = await fetch(`https://navrang.mvd-tech.io/API/login_api.php?${loginData}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

    const result = await response.json();
    console.log(result);
    if (result.message === 'Success' && result.status) {
      if (result.token) {
        localStorage.setItem('authToken', result.token);
        console.log('Login successful! Token saved.');
        toast.success('Login successful!');
        setIsLoggedIn(true);
        setUsername(result.user_name);
        setTimeout(() => {
          history.push('/app_dashboard'); 
        }, 2000);
      } else {
        toast.error('User Not Found');
        setIsLoggedIn(false);
        // console.error('No token received.');
      }
    } else {
      toast.error('User Not Authenticated');
    }
  } catch (error) {
    toast.error('Error during login:', error);
  }
};

export default AuthSign;

