import React, { useEffect, useState, useContext } from 'react';
import toast from 'react-hot-toast';
import { Col, Container, Row } from 'react-bootstrap';
import RequestCard from './RequestCard';
import { RestaurantContext } from '../../../../context/RestaurantContext';

const RequestList = () => {
  const [loading, setLoading] = useState(true);
  const [allRequest, setAllRequest] = useState([]);
  const { setRefresh, statusChanged } = useContext(RestaurantContext);

  const fetchRestResponse = async () => {
    try {
      const url = 'https://navrang.mvd-tech.io/API/restaurant_notappro_list.php';
      const response = await fetch(url);
      const data = await response.json();
      
      if (data.status && data.message === 'Success') {
        setAllRequest(data.data);
        setLoading(false);
      } else if (data.status && data.message === 'No data found') {
        setAllRequest([]);
        setLoading(false);
      } else {
        toast.error('Unable to fetch RequestList');
        setLoading(false);
      }
    } catch (e) {
      toast.error(`Unable to fetch RequestList: ${e.message}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRestResponse();
  }, [setRefresh, statusChanged]);

  if (loading) {
    return (
      <div className="text-center">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return (
    <Container className="d-flex flex-wrap">
      <Row>
        <Col md={12} />
      </Row>
      <Row>
        {allRequest.length > 0 ? (
          allRequest.map((req, index) => (
            <RequestCard 
              key={req.id} 
              index={index} 
              date={req.created_date_time} 
              name={req.name} 
              id={req.id} 
              logo={req.photo}
            />
          ))
        ) : (
          <p>No requests available</p>
        )}
      </Row>
    </Container>
  );
};

export default RequestList;
