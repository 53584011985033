import toast from 'react-hot-toast';

const EditRestaurantData = async (values) => {
    const Start = new Date(values.start_time);
    const formattedStartTime = Start.toLocaleTimeString('en-IN', {
      hour: '2-digit',
      minute: '2-digit',
    });
    const End = new Date(values.end_time);
    const formattedEndTime = End.toLocaleTimeString('en-IN', {
      hour: '2-digit',
      minute: '2-digit',
    });

    const mealOffered = [];
     const mealFormat = [];

     if (values.fit) {
        mealFormat.push('1');
     }
     if (values.git) {
        mealFormat.push('2');
     }
     if (values.delivery) {
        mealFormat.push('3');
     }


    if (values.breakfast) {
        mealOffered.push('1');
    }
    if (values.snacks) {
        mealOffered.push('2');
    }
    if (values.lunch) {
        mealOffered.push('3');
    }
    if (values.dinner) {
        mealOffered.push('4');
    }

    const formData = new FormData();
    formData.append('id', values.id); 
    formData.append('name', values.name); 
    formData.append('address1', values.address1); 
    formData.append('area', values.area); 
    formData.append('state', values.state); 
    formData.append('pincode', values.pincode); 
    formData.append('description', values.description); 
     formData.append('photo', values.logo); 
    formData.append('setting_capacity', values.setting_capacity); 
    formData.append('capping_capacity', values.capping_capacity); 
    formData.append('uin', values.uin);
    formData.append('address2', values.address2); 
    formData.append('city', values.city); 
    formData.append('country', values.country); 
    formData.append('location', values.location); 
    formData.append('cuisines', values.cuisines); 
    formData.append('start_time', formattedStartTime); 
    formData.append('end_time', formattedEndTime); 
    formData.append('tax_id', values.tax_identification); 
    formData.append('markup_percentage', values.markup); 
    formData.append('meal_offered', mealOffered.join(',')); 
    formData.append('meal_format', mealFormat.join(','));  

    try {
        const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_edit.php', {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            console.log(`Network response was not ok: ${response.statusText}`);
        }
        const responseData = await response.json();
        if (responseData.status && responseData.message === 'Data updated successfully.') {
            toast.success('Restaurant Edit Successfully...');
        } else {
            toast.error('error');
        }
    } catch (error) {
        toast.error(error.message);
    }    
};

export default EditRestaurantData;
