import React, {
 createContext, useState, useEffect, useMemo, 
} from 'react';
import PropTypes from 'prop-types';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  const value = useMemo(() => ({ 
    isLoggedIn, 
    setIsLoggedIn, 
    username, 
    setUsername,
  }), [isLoggedIn, username]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;

