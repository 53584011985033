import React, {
    createContext, useState, useEffect, useMemo, 
   } from 'react';
   import PropTypes from 'prop-types';
   
   export const RestaurantContext = createContext();
   
   const RestaurantContextProvider = ({ children }) => {
     const [refresh, setRefresh] = useState(false);
     const [statusChanged, setStatusChanged] = useState(false);

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(() => ({
    
    refresh,
    setRefresh,
    statusChanged,
    setStatusChanged,
  }), [refresh, statusChanged]);

   
     return (
       <RestaurantContext.Provider value={value}>
         {children}
       </RestaurantContext.Provider>
     );
   };
   
   RestaurantContextProvider.propTypes = {
     children: PropTypes.node.isRequired,
   };
   
   export default RestaurantContextProvider;
