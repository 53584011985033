import React from 'react';
import PropTypes from 'prop-types';
import FirebaseIcon from 'mdi-react/FirebaseIcon';
import withAuthFirebase from '@/shared/components/account/auth/withAuthFirebase';
import LogInForm from '@/shared/components/account/loginForm/LogInForm';
import { login as loginAuth0 } from '@/shared/components/account/auth/withAuth0';
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountOr,
  AccountSocial,
  AccountSocialButtonAuth0,
  AccountSocialButtonFirebase,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';
import AuthSign from '../../../utils/AuthSignIn'; 
import GoogleAuthBtn from '../AuthBtn/googleAuthBtn';
import FacebookAuthBtn from '../AuthBtn/fbAuthBtn';
import MetaMaskAuthBtn from '../AuthBtn/metaMaskAuthBtn';
import MicrosoftAuthBtn from '../AuthBtn/microsoftAuthBtn';
import WalletConnectAuthBtn from '../AuthBtn/walletConnectAuthBtn';

const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const LogIn = ({ changeIsOpenModalFireBase }) => (
  <AccountWrap>
    <AccountContent>
      <AccountCard>
        <AccountHead>
          <AccountTitle>
            Welcome to
            <AccountLogo> Bit
              <AccountLogoAccent>Vise</AccountLogoAccent>
            </AccountLogo>
          </AccountTitle>
          {/* <h4 className="subhead" /> */}
        </AccountHead>
        <LogInForm form="log_in_form" />
        {/* <AccountOr>
          <p>Or Easily Using</p>
        </AccountOr> */}
        {/* Add other social login buttons as needed */}
        
      </AccountCard>
    </AccountContent>
  </AccountWrap>
  );

LogIn.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

export default withAuthFirebase(LogIn);
