import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col, Row } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';

const AddContactInfo = ({ formValues, setFormValues, onSubmit }) => (
  <Col md={12} lg={12}>
    <Form onSubmit={onSubmit} initialValues={formValues}>
      {({ handleSubmit, form }) => (
        <FormContainer onSubmit={handleSubmit}>
          {/* Primary Contact Details Section */}
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Primary Contact Details</CardTitle>
              </CardTitleWrap>
              {/* First Row: 2 columns */}
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>Name</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="userName"
                        component="input"
                        type="text"
                        placeholder="Name"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>Phone Number</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="userPhone"
                        component="input"
                        type="number"
                        placeholder="Phone Number"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
              </Row>

              {/* Second Row: 2 columns */}
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>Email</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="userEmail"
                        component="input"
                        type="email"
                        placeholder="Email"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>UIN #</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="uin"
                        component="input"
                        type="number"
                        placeholder="UIN"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {/* Account Details Section */}
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Account Details</CardTitle>
              </CardTitleWrap>
              {/* Third Row: 2 columns */}
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>Account Name</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="accName"
                        component="input"
                        type="text"
                        placeholder="Account Name"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>Country</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="accCountry"
                        component="input"
                        type="text"
                        placeholder="Country"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
              </Row>

              {/* Fourth Row: 2 columns */}
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>Account</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="accountNo"
                        component="input"
                        type="number"
                        placeholder="Account Number"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>City</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="accCity"
                        component="input"
                        type="text"
                        placeholder="City"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
              </Row>

              {/* Fifth Row: 2 columns */}
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>Confirm Account</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="conAccount"
                        component="input"
                        type="number"
                        placeholder="Confirm Account"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>Branch</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="branch"
                        component="input"
                        type="text"
                        placeholder="Branch"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
              </Row>

              {/* Sixth Row: 2 columns */}
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>IFSC</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="ifscCode"
                        component="input"
                        type="text"
                        placeholder="IFSC"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <FormGroupLabel>Bank</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="bank"
                        component="input"
                        type="text"
                        placeholder="Bank"
                      />
                    </FormGroupField>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {/* Submit and Cancel buttons */}
          <div className="text-right mt-3">
            <FormButtonToolbar>
              <Button variant="primary" type="submit">Submit</Button>
              {/* <Button variant="secondary" type="button" onClick={form.reset}>
                Reset
              </Button> */}
            </FormButtonToolbar>
          </div>
        </FormContainer>
      )}
    </Form>
  </Col>
);

AddContactInfo.propTypes = {
  formValues: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    agentName: PropTypes.string,
    addressline1: PropTypes.string,
    addressline2: PropTypes.string,
    area: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    tin: PropTypes.string,
    taxCity: PropTypes.string,
    defaultMarkup: PropTypes.string,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userPhone: PropTypes.string,
    uin: PropTypes.number,
    accName: PropTypes.string,
    accountNo: PropTypes.number,
    ifscCode: PropTypes.string,
    branch: PropTypes.string,
    bank: PropTypes.string,
    accCity: PropTypes.string,
    accCountry: PropTypes.string,
  }).isRequired,
  setFormValues: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddContactInfo;
