import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import renderSelectField from '@/shared/components/form/Select';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
    FormHalfContainer,
  } from '@/shared/components/form/FormElements';
  import renderCheckBoxField from '@/shared/components/form/CheckBox';
  import renderDropZoneField from '@/shared/components/form/dropzones/DropZone';
  import { Button } from '@/shared/components/Button';
  import PropTypes from 'prop-types';
  import { CiCirclePlus } from 'react-icons/ci';
  import { IoIosRemoveCircle } from 'react-icons/io';
  import { Table } from '@/shared/components/TableElements';
  import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import { margin } from 'polished';
import renderRadioButtonField from '@/shared/components/form/RadioButton';


const AddMeal = ({ onSubmit }) => { 
 const [selectedImage, setSelectedImage] = useState(null);
 const [fetchRestaurantName, setFetchRestaurantName] = useState([]);

  const fetchRestaurant = async () => {
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_data.php');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status && data.message === 'Success') {
        setFetchRestaurantName(data.data.map(item => ({ value: item.id, label: item.name })));
        console.log(JSON.stringify(fetchRestaurantName));
      }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  };

  useEffect(() => {
    fetchRestaurant();
  }, []);

    // handle file
    const handleFileChange = (event, input) => {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        input.onChange(file);
      } else {
        setSelectedImage(null);
        input.onChange(null);
      }
    };

    const [meals, setMeals] = useState([]);
     const handleAddMeal = (values) => {
    const newMeal = {
      item_id: meals.length + 1, 
      name: values.Item, 
      description: values.desc, 
    };
    setMeals(prevMeals => [...prevMeals, newMeal]);
  };

       
     
  const [isChecked, setIsChecked] = useState(false);
  const [rememberme, setRemember] = useState(false);

  const handleCheckboxChange = () => {
    
  };
    
 
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle />
          </CardTitleWrap>
          <Form onSubmit={onSubmit} initialValues={{ meals }}>
            {({ handleSubmit, form }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Select Restaurant</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="restaurantid"
                        component={renderSelectField}
                        type="text"
                        placeholder="Select Restaurant"
                        options={fetchRestaurantName} 
                          
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal Name</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="mealName"
                        component="input"
                        type="text"
                        placeholder="Meal Name"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal Price</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="mealPrice"
                        component="input"
                        type="number"
                        placeholder="Meal Price"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>FOC</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="foc"
                        component="input"
                        type="text"
                        placeholder="Foc"
                        className="w-60"
                      />
                      {' '}on
                      <Field
                        name="focon"
                        component="input"
                        type="text"
                        placeholder="Foc on"
                        className="w-60"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Item</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {meals.map((meal, index) => (
                          <tr key={meal.id}>
                            <td>{index + 1}</td>
                            <td>{meal.name}</td>
                            <td>{meal.description}</td>
                            <td>
                              <Button 
                                type="button"
                                variant="outline-danger" 
                                size="sm"
                                className="mt-2"
                                onClick={() => setMeals(meals.filter(m => m.id !== meal.id))}
                              >
                                <IoIosRemoveCircle />
                              </Button>
                            </td>
                          </tr>
                           ))}

                      </tbody>
                      <tfoot>
                        <tr>
                          <th>
                            <FormGroupLabel>Item</FormGroupLabel>
                            <Field
                              name="Item"
                              component="input"
                              type="text"
                              placeholder="Item"
                        
                            />
                          </th>
                          <th>
                            <FormGroupLabel>Description</FormGroupLabel>
                            <Field
                              name="desc"
                              component="input"
                              type="text"
                              placeholder="description"
                            />
                          </th>
                          <th>
                            
                            <Button 
                              type="button"
                              variant="outline-success" 
                              rounded
                              size="sm"
                              style={{
                                marginTop: '45px',
                              }}
                              onClick={() => {
                                handleAddMeal({
                                  Item: document.querySelector('[name="Item"]').value,
                                  desc: document.querySelector('[name="desc"]').value,
                                });
                                document.querySelector('[name="Item"]').value = '';
                                document.querySelector('[name="desc"]').value = '';
                              }}
                              
                            >
                              <CiCirclePlus />
                            </Button>
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal Offered</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="breakfast"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Breakfast"
                        onChange={handleCheckboxChange} 
                      />
                      <Field
                        name="snaks"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Snacks"
                        onChange={handleCheckboxChange} 
                      />
                      <Field
                        name="lunch"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Lunch"
                        onChange={handleCheckboxChange} 
                      />
                      <Field
                        name="dinner"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Dinner"
                        onChange={handleCheckboxChange} 
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal format</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="format"
                        component={renderRadioButtonField}
                        label="Fit"
                        radioValue="1"
                      />
                      <Field
                        name="format"
                        component={renderRadioButtonField}
                        label="Git"
                        radioValue="2"
                      />
                      <Field
                        name="format"
                        component={renderRadioButtonField}
                        label="Delivery"
                        radioValue="3"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Veg/Non-veg</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="veg"
                        component={renderRadioButtonField}
                        label="Veg"
                        radioValue="1"
                      />
                      <Field
                        name="veg"
                        component={renderRadioButtonField}
                        label="Non-veg"
                        radioValue="2"
                      />
                      <Field
                        name="veg"
                        component={renderRadioButtonField}
                        label="Veg/Non-veg"
                        radioValue="3"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Cuisines</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="cuisines"
                        component="input"
                        type="text"
                        placeholder="Cuisines"
                       
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal type</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="mealType"
                        component="input"
                        type="text"
                        placeholder="Meal Type"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                </FormHalfContainer>

                <FormHalfContainer>
                  
                  <FormGroup>
                    <FormGroupLabel>Description</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="discription"
                        component="textarea"
                        type="text"
                        placeholder="Description"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Logo</FormGroupLabel>
                    <FormGroupField>
                      <Field 
                        name="logo"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handleFileChange(e, input)}
                            required
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>

                  {selectedImage && (
                    <FormGroup>
                      <FormGroupLabel>Selected Logo</FormGroupLabel>
                      <FormGroupField>
                        <img
                          src={selectedImage}
                          alt="Selected Logo"
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                      </FormGroupField>
                    </FormGroup>
                  )}
                  
                  <FormGroup>
                    <FormGroupLabel>Start Date</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="startDate"
                        component={renderDatePickerField}
                        type="date"
                        placeholder="Start Date"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>End Date</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="endDate"
                        component={renderDatePickerField}
                        type="date"
                        placeholder="End Date"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Markup Percentage</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="markup"
                        component="input"
                        type="number"
                        placeholder="Markup Percentage"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormButtonToolbar>
                    <Button variant="primary" type="submit">Submit</Button>
                    <Button variant="secondary" type="button">
                      Reset
                    </Button>
                  </FormButtonToolbar>
                </FormHalfContainer>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
    
  );
};
AddMeal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
export default AddMeal;
