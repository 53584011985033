import React, { useState } from 'react';
import { Nav, Tab, Modal } from 'react-bootstrap';
import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';
import { FaPlus } from 'react-icons/fa';
import { Button } from '@/shared/components/Button';
import toast from 'react-hot-toast';
import AddRestaurant from './AddRestaurant';
import AddNewRestaurant from '../utils/AddRestautant';
import RequestList from './RequestList';
import Search from './Search';

const Tabs = () => {
  const [showModal, setShowModal] = useState(false);
  // state for component condition rendering 
  const [condition, setCondition] = useState('searchRestaurant');

  // Handler for tab selection
  const handleTabSelect = (key) => {
    switch (key) {
      case '1':
        setCondition('searchRestaurant');
        break;
      case '2':
        setCondition('requestRestaurant');
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* Tabs */}
      <Tab.Container defaultActiveKey="1" onSelect={handleTabSelect}>
        <TabsWrap>
          <Button 
            variant="outline-primary" 
            size="sm" 
            onClick={() => setShowModal(true)}
          >
            <span>Add Restaurant</span><FaPlus />
          </Button>
        
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink eventKey="1">Search</NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="2">Request</NavLink>
            </NavItem>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="1">
              {condition === 'searchRestaurant' && <Search />}
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              {condition === 'requestRestaurant' && <RequestList />}
            </Tab.Pane>
          </Tab.Content>
        </TabsWrap>
      </Tab.Container>

      {/* Modal for Adding Restaurant */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Restaurant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddRestaurant onSubmit={AddNewRestaurant} />
        </Modal.Body>
        <Modal.Footer>
          {/* Optional Close button can be added if needed */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tabs;
