import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const AgentContext = createContext();

const AgentContextProvider = ({ children }) => {
  const [isSelect, setIsSelect] = useState('searchSelect');
  const [refresh, setRefresh] = useState(false);
  const [refreshRequest, setRefreshRequest] = useState(false);
  const [agentData, setAgentdata] = useState({
    id: '',
    agentName: '',
    uan: '',
    addressLine1: '',
    addressLine2: '',
    area: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    locationOnMap: '',
    logo: null,
    defaultMarkup: '',
    taxId: '',
    taxCity: '',
    user_name: '',
    user_phone: '',
    user_email: '',
    uin: '',
    acc_name: '',
    account_no: '',
    ifsc_code: '',
    branch: '',
    bank: '',
    acc_city: '',
    acc_country: '',
  });
  const [isDelete, setIsDelete] = useState(0);
  const [statusChanged, setStatusChanged] = useState(false);

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(() => ({
    agentData,
    setAgentdata,
    isDelete,
    setIsDelete,
    isSelect,
    setIsSelect,
    refresh,
    setRefresh,
    refreshRequest, 
    setRefreshRequest,
    statusChanged,
    setStatusChanged,
  }), [agentData, isDelete, isSelect, refresh, refreshRequest, setRefreshRequest, statusChanged, setStatusChanged]);

  return (
    <AgentContext.Provider value={value}>
      {children}
    </AgentContext.Provider>
  );
};

AgentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AgentContextProvider;
