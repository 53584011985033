import React, { useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { GrNotes } from 'react-icons/gr';
import { MdOutlineLunchDining } from 'react-icons/md';
import { ImSpoonKnife } from 'react-icons/im';
import { FaLocationDot } from 'react-icons/fa6';
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';

import { FiAlignRight } from 'react-icons/fi';
import { IoMdTime, IoMdCloseCircle, IoMdPeople } from 'react-icons/io';
import EditRestaurant from './EditRestaurant';
import EditRestaurantData from '../utils/EditRestaurant';
import DeleteConfirmationModal from './DeleteModal';

const RestaurantCard = ({ 
   id,
   name,
   format,
   mealOffered,
   location,
   cuisines,
   capacity,
   clipcapacity,
   image,
}) => {
  const logo = `https://navrang.mvd-tech.io/API/images/restaurant/${image}`;
  
  const [mealOfferds, setMealOffereds] = useState([]);
  const [formatOfferds, setFormatOffereds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteModalShow, setDeleteModal] = useState(false);
  
  // Toggle the Edit Restaurant modal
  const toggleModal = () => setShowModal(!showModal);

  
  const handleDeleteModalShow = () => setDeleteModal(true);
  const handleDeleteModalClose = () => setDeleteModal(false);

  // Map the meal and format options
  useEffect(() => {
    if (mealOffered) {
      const mealOffer = mealOffered.split(','); 
      const mappedMeals = mealOffer.map((meal) => {
        switch (meal) {
          case '1':
            return 'Breakfast';
          case '2':
            return 'Snacks';
          case '3':
            return 'Lunch';
          case '4':
            return 'Dinner';
          default:
            return ''; 
        }
      });
      setMealOffereds(mappedMeals); 
    }
  }, [mealOffered]); 

  useEffect(() => {
    if (format) {
      const formatOffer = format.split(','); 
      const mappedFormat = formatOffer.map((formats) => {
        switch (formats) {
          case '1':
            return 'FIT';
          case '2':
            return 'GIT';
          case '3':
            return 'DELIVERY';
          default:
            return ''; 
        }
      });
      setFormatOffereds(mappedFormat); 
    }
  }, [format]);

 
  const handleDeleteClick = async (ids) => {
      try {
        const deleteurl = await fetch(`https://navrang.mvd-tech.io/API/restaurant_delete.php?id=${ids}`);
        const response = await deleteurl.json();
         if (response.status && response.message === 'Data deleted successfully') {
           toast.success('Restaurant deleted successfully');
            handleDeleteModalClose(); 
         } else {
           toast.error('Error deleting restaurant');
         }
      } catch (error) {
        toast.error(`Error deleting restaurant,${error.message}`);
      }
  };

  return (
    <div className="card mb-3 position-relative" style={{ maxWidth: '100%' }}>
      <div className="row mt-2">
        <div className="col-md-3 col-12">
          <img 
            src={logo} 
            alt={name} 
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover',
              marginTop: '12px',
              marginBottom: '12px',
              borderRadius: '10px',
            }} 
          />
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <h3 className="card-text" style={{ color: 'black' }}>
                  {name}
                </h3>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <span><GrNotes /> Format: {formatOfferds.join(',')}</span> 
                  <p className="mt-4"><MdOutlineLunchDining /> Cuisines: {cuisines}</p>
                </div>
                <div className="col-md-4">
                  <span><ImSpoonKnife /> Meals: {mealOfferds.join(',')}</span> 
                  <p className="mt-4"><IoMdPeople /> Capacity: {capacity}</p>
                </div>
                <div className="col-md-4">
                  <p><FaLocationDot /> Location: {location}</p> 
                  <p className="mt-4"><IoMdPeople /> Clip Capacity: {clipcapacity}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        show={deleteModalShow}
        handleClose={handleDeleteModalClose}
        handleDelete={() => handleDeleteClick(id)}
        message="Are you sure you want to delete this restaurant?"
      />

      {/* Edit Restaurant Modal */}
      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="exampleModalLabel">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">Edit Restaurant </h3>
                <Button type="button" variant="primary" rounded size="sm" onClick={toggleModal} aria-label="Close">
                  <IoMdCloseCircle className="close-icon" />
                </Button>
              </div>
              <div className="modal-body">
                <EditRestaurant onSubmit={EditRestaurantData} id={id} />
              </div>
            </div>
          </div>
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ButtonGroup icons dir="ltr" className="mt-2 mx-2">
          <Button variant="outline-success" title="Edit" onClick={toggleModal}>
            <span className="lnr lnr-pencil" /> Edit          
          </Button>
          <Button variant="outline-danger" title="Delete" onClick={handleDeleteModalShow}>
            <span className="lnr lnr-trash" /> Delete
          </Button>
          <Button variant="outline-primary" title="Details">
            <span className="lnr lnr-file-empty" /> Details
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

RestaurantCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  cuisines: PropTypes.string.isRequired,
  mealOffered: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  capacity: PropTypes.string.isRequired,
  clipcapacity: PropTypes.string.isRequired,
};

export default RestaurantCard;
