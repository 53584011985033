import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DefaultTabsBorderedBottom from './component/DefaultTabsBorderedBottom';




const Restaurant = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <Row>
        <DefaultTabsBorderedBottom />
      </Row>
    </Container>
  );
};
export default Restaurant;
