import React, { useState, useEffect, useContext } from 'react';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { CiCirclePlus } from 'react-icons/ci';
import renderSelectField from '@/shared/components/form/Select';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import { Button } from '@/shared/components/Button';
import { IoIosRemoveCircle } from 'react-icons/io';
import PropTypes from 'prop-types';
import { Table } from '@/shared/components/TableElements';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import toast from 'react-hot-toast';
import { Mealcontext } from '../../../../context/MealContext';

const AddEditMeal = ({ onSubmit, id }) => {
  const { meals, setMeals } = useContext(Mealcontext);
  const isChecked = true;
  const [prevData, setPrevData] = useState(null); 
  const [selectedid, setSelectedId] = useState(0);
  const [selectedName, setSelectedName] = useState('');
  // const [meals, setMeals] = useState([]); 
  const [selectedImage, setSelectedImage] = useState(null);
  const [fetchRestaurantName, setFetchRestaurantName] = useState([]);
  
  const fetchRestaurant = async () => {
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_data.php');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status && data.message === 'Success') {
        setFetchRestaurantName(data.data.map(item => ({ value: item.id, label: item.name })));
        console.log(JSON.stringify(fetchRestaurantName));
      }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  };

  useEffect(() => {
    fetchRestaurant();
  }, []);

  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      input.onChange(file);
    } else {
      setSelectedImage(null);
      input.onChange(null);
    }
  };

  const handleAddMeal = (values) => {
    const newMeal = {
      item_id: meals.length + 1, 
      name: values.Item,        
      description: values.desc,   
    };
    setMeals(prevMeals => [...prevMeals, newMeal]); 
  };

  useEffect(() => {
    let isMounted = true;

    const fetchDataForEdit = async () => {
      try {
        const fetchMeal = await fetch(`https://navrang.mvd-tech.io/API/meal_data_fetch.php?id=${id}`);
        const res = await fetchMeal.json();
        if (isMounted) {
          if (res.status && res.message === 'Success') {
            setPrevData(res.data[0]);
            setSelectedId(res.data[0].restaurant_id);
            setSelectedName(res.data.restaurant_name);
            try {
              const itemsArray = JSON.parse(res.data[0].items);
              setMeals(itemsArray); 
            } catch (e) {
              toast.error('Failed to parse meal items.');
            }

            if (res.data[0].photo) {
              setSelectedImage(`https://navrang.mvd-tech.io/API/images/meal/${res.data[0].photo}`);
            }
          } else {
            toast.error('Failed to fetch meal data for edit.');
          }
        }
      } catch (error) {
        if (isMounted) {
          toast.error(`Failed to fetch: ${error.message}`);
        }
      }
    };

    fetchDataForEdit();

    return () => {
      isMounted = false;
    };
  }, [id]);

  // console.log(meals);

  const getMealOfferedChecked = (mealOffered, type) => {
    const mealMap = {
      1: 'breakfast',
      2: 'snaks',
      3: 'lunch',
      4: 'dinner',
    };
  
    return mealOffered && mealMap[mealOffered] === type;
  };

  const getMealFormatChecked = (mealFormat, type) => {
    const mealMap = {
      1: 'fit',
      2: 'git',
      3: 'delivery',
    };
  
    return mealFormat && mealMap[mealFormat] === type;
  };

  const getMealvegChecked = (mealveg, type) => {
    const mealMap = {
      1: 'veg',
      2: 'nonveg',
      3: 'vegNonveg',
    };
  
    return mealveg && mealMap[mealveg] === type;
  };
  

  // Debug: Log mealOffered check result for breakfast
  // useEffect(() => {
  //   if (prevData) {
  //     console.log(getMealOfferedChecked(prevData.meal_offered, 'breakfast'));
  //   }
  // }, [prevData]); 
useEffect(() => {
  if (prevData) {
    const StartDate = prevData.start_date; 
    const EndDate = prevData.end_date;
    
    const [startYear, startMonth, startDay] = StartDate.split('-');
    const formattedStartDate = `${startDay}-${startMonth}-${startYear}`;
    const [endYear, endMonth, endDay] = EndDate.split('-');
    const formattedEndDate = `${endDay}-${endMonth}-${endYear}`;
    
    // console.log(formattedStartDate); 
    // console.log(formattedEndDate);\
    prevData.start_date = formattedStartDate;
    prevData.end_date = formattedEndDate;
  }
}, [prevData]);




  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle />
          </CardTitleWrap>
          {prevData ? ( 
            <Form onSubmit={onSubmit} initialValues={prevData}>
              {({ handleSubmit, form }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <FormHalfContainer>
                    <FormGroup>
                      <FormGroupLabel>Select Restaurant</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="restaurant_id"
                          component={renderSelectField}
                          type="text"
                          label="Restaurant"
                          placeholder={!selectedName ? 'Select a Restaurant' : selectedName}
                          initialValue={selectedid}
                          options={fetchRestaurantName}
                        />
                      </FormGroupField>

                    </FormGroup>

                    <FormGroup>
                      <FormGroupLabel>Meal Name</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="id"
                          component="input"
                          type="number"
                          placeholder="UID"
                          style={{ display: 'none' }}
                        />
                        <Field
                          name="name"
                          component="input"
                          type="text"
                          placeholder="Meal Name"
                        />
                      </FormGroupField>
                    </FormGroup>
                    <FormGroup>
                      <FormGroupLabel>Meal Price</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="price"
                          component="input"
                          type="number"
                          placeholder="Meal Price"
                        />
                      </FormGroupField>
                    </FormGroup>
                    <FormGroup>
                      <FormGroupLabel>FOC</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="foc"
                          component="input"
                          type="text"
                          placeholder="Foc"
                          className="w-60"
                        />
                        {' '}on
                        <Field
                          name="foc_on"
                          component="input"
                          type="text"
                          placeholder="Foc on"
                          className="w-60"
                        />
                      </FormGroupField>
                    </FormGroup>

                    {/* Meal Items Table */}
                    <FormGroup>
                      <Table responsive hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {meals.map((meal, index) => (
                            <tr key={meal.id}>
                              <td>{index + 1}</td>
                              <td>{meal.name}</td>
                              <td>{meal.description}</td>
                              <td>
                                <Button 
                                  type="button"
                                  variant="outline-danger" 
                                  size="sm"
                                  className="mt-2"
                                  onClick={() => setMeals(meals.filter(m => m.id !== meal.id))}
                                >
                                  <IoIosRemoveCircle />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>
                              <FormGroupLabel>Item</FormGroupLabel>
                              <Field
                                name="Item"
                                component="input"
                                type="text"
                                placeholder="Item"
                              />
                            </th>
                            <th>
                              <FormGroupLabel>Description</FormGroupLabel>
                              <Field
                                name="desc"
                                component="input"
                                type="text"
                                placeholder="Description"
                              />
                            </th>
                            <th>
                              <Button
                                type="button"
                                variant="outline-success"
                                rounded
                                size="sm"
                                style={{ marginTop: '45px' }}
                                onClick={() => {
                                  // Collect the field values using React Final Form's form API
                                  const itemValue = document.querySelector('[name="Item"]').value;
                                  const descValue = document.querySelector('[name="desc"]').value;

                                  // Add meal item
                                  handleAddMeal({
                                    Item: itemValue,
                                    desc: descValue,
                                  });

                                  // Clear the form fields using form.change
                                  form.change('Item', '');
                                  form.change('desc', '');
                                }}
                              >
                                <CiCirclePlus />
                              </Button>
                            </th>
                          </tr>
                        </tfoot>

                      </Table>
                    </FormGroup>

                    {/* Meal Offered Checkboxes */}
                    <FormGroup>
                      <FormGroupLabel>Meal Offered</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="breakfast"
                          type="checkbox"
                          component={renderCheckBoxField}
                          label="Breakfast"
                          initialValue={getMealOfferedChecked(prevData.meal_offered, 'breakfast')}
                        />
                        <Field
                          name="snaks"
                          type="checkbox"
                          component={renderCheckBoxField}
                          label="Snacks"
                          initialValue={getMealOfferedChecked(prevData.meal_offered, 'snaks')}
                        />
                        <Field
                          name="lunch"
                          type="checkbox"
                          component={renderCheckBoxField}
                          label="Lunch"
                          initialValue={getMealOfferedChecked(prevData.meal_offered, 'lunch')}
                        />
                        <Field
                          name="dinner"
                          type="checkbox"
                          component={renderCheckBoxField}
                          label="Dinner"
                          initialValue={getMealOfferedChecked(prevData.meal_offered, 'dinner')}
                        />
                      </FormGroupField>
                    </FormGroup>

                    {/* Additional Fields */}
                    <FormGroup>
                      <FormGroupLabel>Meal format</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="format"
                          component={renderRadioButtonField}
                          label="Fit"
                          radioValue="1"
                          initialValue={getMealFormatChecked(prevData?.meal_format, 'fit') ? '1' : undefined}
                        />
                        <Field
                          name="format"
                          component={renderRadioButtonField}
                          label="Git"
                          radioValue="2"
                          initialValue={getMealFormatChecked(prevData?.meal_format, 'git') ? '2' : undefined}
                        />
                        <Field
                          name="format"
                          component={renderRadioButtonField}
                          label="Delivery"
                          radioValue="3"
                          initialValue={getMealFormatChecked(prevData?.meal_format, 'delivery') ? '3' : undefined}
                        />
                      </FormGroupField>
                    </FormGroup>

                    {/* Veg/Non-veg Radio Buttons */}
                    <FormGroup>
                      <FormGroupLabel>Veg/Non-veg</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="veg"
                          component={renderRadioButtonField}
                          label="Veg"
                          radioValue="1"
                          initialValue={getMealvegChecked(prevData?.veg_non_veg, 'veg') ? '1' : undefined}
                        />
                        <Field
                          name="veg"
                          component={renderRadioButtonField}
                          label="Non-veg"
                          radioValue="2"
                          initialValue={getMealvegChecked(prevData?.veg_non_veg, 'nonveg') ? '2' : undefined}
                        />
                        <Field
                          name="veg"
                          component={renderRadioButtonField}
                          label="Veg/Non-veg"
                          radioValue="3"
                          initialValue={getMealvegChecked(prevData?.veg_non_veg, 'vegNonveg') ? '3' : undefined}
                        />
                      </FormGroupField>
                    </FormGroup>

                    {/* Cuisines */}
                    <FormGroup>
                      <FormGroupLabel>Cuisines</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="cuisines"
                          component="input"
                          type="text"
                          placeholder="Cuisines"
                        />
                      </FormGroupField>
                    </FormGroup>

                    {/* Meal type */}
                    <FormGroup>
                      <FormGroupLabel>Meal type</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="meal_type"
                          component="input"
                          type="text"
                          placeholder="Meal Type"
                        />
                      </FormGroupField>
                    </FormGroup>
                  </FormHalfContainer>

                  {/* File Upload, Date Picker and Markup Percentage */}
                  <FormHalfContainer>
                    <FormGroup>
                      <FormGroupLabel>Description</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="description"
                          component="textarea"
                          placeholder="Description"
                        />
                      </FormGroupField>
                    </FormGroup>

                    <FormGroup>
                      <FormGroupLabel>Logo</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="logo"
                          render={({ input }) => (
                            <input
                              type="file"
                              onChange={e => handleFileChange(e, input)}
                            />
                          )}
                        />
                      </FormGroupField>
                    </FormGroup>

                    {selectedImage && (
                      <FormGroup>
                        <FormGroupLabel>Selected Logo</FormGroupLabel>
                        <FormGroupField>
                          <img
                            src={selectedImage}
                            alt="Selected Logo"
                            style={{ maxWidth: '200px', maxHeight: '200px' }}
                          />
                        </FormGroupField>
                      </FormGroup>
                    )}

                    <FormGroup>
                      <FormGroupLabel>Start Date</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="start_date"
                          component={renderDatePickerField}
                          type="date"
                          placeholder="Start Date"
                        />
                      </FormGroupField>
                    </FormGroup>

                    <FormGroup>
                      <FormGroupLabel>End Date</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="end_date"
                          component={renderDatePickerField}
                          type="date"
                          placeholder="End Date"
                        />
                      </FormGroupField>
                    </FormGroup>

                    <FormGroup>
                      <FormGroupLabel>Markup Percentage</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="markup_percentage"
                          component="input"
                          type="number"
                          placeholder="Markup Percentage"
                        />
                      </FormGroupField>
                    </FormGroup>

                    <FormButtonToolbar>
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                      <Button variant="secondary" type="button">
                        Reset
                      </Button>
                    </FormButtonToolbar>
                  </FormHalfContainer>
                </FormContainer>
              )}
            </Form>
          ) : (
            <p>Loading data...</p> 
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

AddEditMeal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default AddEditMeal;
