import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Col, 
  Container, 
  Row, 
  Form,
} from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import {
   Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import { AgentContext } from '../../context/AgentContext';
import RequestShort from './RequestShort';

const RequestList = () => {
  const [request, setRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [errors, setError] = useState(null); 
  const [statuschanged, setStatusChanged] = useState(false);
  const { setRefresh, refreshRequest, statusChanged } = useContext(AgentContext);

  const fetchData = async () => {
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/agent_notappro_list.php');
      const data = await response.json();
      if (data.status && data.message === 'Success') {
        setRequest(data.data);
      } else {
        setRequest([]);
      }
    } catch (error) {
      setError('Failed to load data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [statusChanged, refreshRequest]);

  // Loading and error states rendering
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>{errors}</div>;
  }

  // const handleReject = async (id) => {
  //   try {
  //     const response = await fetch(
  //       `https://navrang.mvd-tech.io/API/agent_confirmation.php?id=${id}&isApproved=2`,
  //       { method: 'GET' },
  //     );
  //     const result = await response.json();
  //     if (result.status && result.message === 'Success') {
  //       setStatusChanged(true);
  //     } else {
  //       setStatusChanged(false);
  //       fetchData();
  //     }
  //   } catch (error) {
  //     fetchData();
  //   }
  // };
  
  // const handleAccept = async (id) => {
  //   try {
  //     const response = await fetch(
  //       `https://navrang.mvd-tech.io/API/agent_confirmation.php?id=${id}&isApproved=1`,
  //       { method: 'GET' },
  //     );
  //     const result = await response.json();

  //     if (result.status && result.message === 'Success') {
  //       setStatusChanged(true);
  //       setRefresh(prev => !prev);
  //     } else {
  //       setStatusChanged(false);
  //     }
  //   } catch (error) {
  //     fetchData();
  //   }
  // };
  return (
    <Container className="d-flex flex-wrap ">
      <Row>
        <Col md={12} />
      </Row>      
      <Row>
        {request.length > 0 ? (
  request.map((req, index) => (
    <RequestShort 
      key={req.id} 
      index={index} 
      date={req.date} 
      name={req.name} 
      id={req.id} 
      logo={req.logo}             
    />
  ))
) : (
  <p>No requests available</p>
)}
      </Row>
   
      
      {/* <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort />
      <RequestShort /> */}

    
      {/* // <Col md={12} lg={12} className="">
    //   <Card className="">
    //     <CardBody>
    //       <CardTitleWrap>
    //         <CardSubhead>
    //           <span className="red-text">REQUEST NOT APPROVED YET</span>
    //         </CardSubhead>
    //       </CardTitleWrap>
    //       <Table responsive bordered>
    //         <thead>
    //           <tr>
    //             <th>#</th>
    //             <th>Date</th>
    //             <th>Name</th>
    //             <th>Request Type</th>
    //             <th>Action</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {request.length > 0 ? ( */}
      {/* //               request.map((req, index) => (
    //                 <tr key={req.id || index}>
    //                   <td>{index + 1}</td>
    //                   <td>{req.date}</td>
    //                   <td>{req.name}</td>
    //                   <td>{req.status === '0' ? 'Not Approved' : 'Approved'}</td>
    //                   <td>
    //                     <Button */}
      {/* //                       size="sm"
    //                       variant="outline-success"
    //                       onClick={() => handleAccept(req.id)}
    //                     >
    //                       Confirm
    //                     </Button>
    //                     <Button
    //                       size="sm"
    //                       className="mx-2"
    //                       variant="outline-danger"
    //                       onClick={() => handleReject(req.id)}
    //                     >
    //                       Reject
    //                     </Button>
    //                   </td>
    //                 </tr>
    //               ))
    //             ) : (
    //               <tr>
    //                 <td colSpan="5">No requests found</td>
    //               </tr>
    //             )}
    //         </tbody>
    //       </Table>
    //     </CardBody>
    //   </Card>
    // </Col> */}
    </Container>
  );
};


export default RequestList;
