import toast from 'react-hot-toast';

const MealDelete = async (id, setRefresh) => {
    try {
        const deletemealUrl = `https://navrang.mvd-tech.io/API/meal_delete.php?id=${id}`;
        console.log(`Fetching URL: ${deletemealUrl}`);
        
        const deletemealUrlResponse = await fetch(deletemealUrl);

        if (!deletemealUrlResponse.ok) {
            throw new Error(`HTTP error! Status: ${deletemealUrlResponse.status}`);
        }

        const deletemealResponse = await deletemealUrlResponse.json();
        console.log(deletemealResponse); 

        if (deletemealResponse.status && deletemealResponse.message === 'Meal record deleted successfully') {
            toast.success('Meal deleted successfully');
            setRefresh(prev => !prev);
        } else {
            toast.error('Failed to delete meal');
        }
    } catch (error) {
        console.error('Error occurred during meal deletion:', error);
        toast.error(`Failed to delete: ${error.message}`);
    }
};

export default MealDelete;

