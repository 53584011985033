import React, {
    createContext, useState, useEffect, useMemo, 
   } from 'react';
   import PropTypes from 'prop-types';
   
   export const Mealcontext = createContext();
   
   const MealContextProvider = ({ children }) => {
     const [refresh, setRefresh] = useState(false);
     const [username, setUsername] = useState('');
     const [meals, setMeals] = useState([]); 
     const [isDetailsClick, setIsDetailsClick] = useState(false);
     const [resaurantDetailsId, setRestaurantDetailsId] = useState();
     const [click, setclick] = useState('searchSelect');
     const [activeKey, setActiveKey] = useState('1');
     const value = useMemo(() => ({ 
        refresh, 
        setRefresh, 
        meals,
        setMeals,
        setIsDetailsClick,
        isDetailsClick,
        resaurantDetailsId,
        setRestaurantDetailsId,
        click, 
        setclick,
        activeKey, 
        setActiveKey,
     }), [refresh, meals, isDetailsClick, resaurantDetailsId, click]);
   
     return (
       <Mealcontext.Provider value={value}>
         {children}
       </Mealcontext.Provider>
     );
   };
   
   MealContextProvider.propTypes = {
     children: PropTypes.node.isRequired,
   };
   
   export default MealContextProvider;
