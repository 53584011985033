import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import renderFileInputField from '@/shared/components/form/FileInput';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';

const AddAgent = ({ onSubmit }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      input.onChange(file);
    } else {
      setSelectedImage(null);
      input.onChange(null);
    }
  };

  const required = value => (value ? undefined : 'This field is required');

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle />
          </CardTitleWrap>
          <Form onSubmit={onSubmit} initialValues={{ gender: 'male' }}>
            {({ handleSubmit, form }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Agent Name</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="agentName"
                        component="input"
                        type="text"
                        placeholder="Name"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 1</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="addressline1"
                        component="input"
                        type="text"
                        placeholder="Address line 1"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Area</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="area"
                        component="input"
                        type="text"
                        placeholder="Area"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>State</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="state"
                        component="input"
                        type="text"
                        placeholder="State"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Pincode</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="pincode"
                        component="input"
                        type="number"
                        placeholder="Pincode"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Logo</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="file"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handleFileChange(e, input)}
                            required
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>

                  {selectedImage && (
                    <FormGroup>
                      <FormGroupLabel>Selected Logo</FormGroupLabel>
                      <FormGroupField>
                        <img
                          src={selectedImage}
                          alt="Selected Logo"
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                      </FormGroupField>
                    </FormGroup>
                  )}
                </FormHalfContainer>

                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>UAN</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="uan"
                        component="input"
                        type="number"
                        placeholder="UAN"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 2</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="addressline2"
                        component="input"
                        type="text"
                        placeholder="Address Line 2"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>City</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="city"
                        component="input"
                        type="text"
                        placeholder="City"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Select your country</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="country"
                        component="input"
                        type="text"
                        placeholder="Country"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Location on Map</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="location"
                        component="input"
                        type="text"
                        placeholder="Location"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Default Markup</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="markup"
                        component="input"
                        type="number"
                        placeholder="Default Markup"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>TAX Identification</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="taxid"
                        component="input"
                        type="text"
                        placeholder="TAX Identification"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>TAX City</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="taxCity"
                        component="input"
                        type="text"
                        placeholder="Tax City"
                        validate={required}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormButtonToolbar>
                    <Button variant="primary" type="submit">Submit</Button>
                    <Button variant="secondary" type="button" onClick={form.reset}>
                      Reset
                    </Button>
                  </FormButtonToolbar>
                </FormHalfContainer>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

AddAgent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AddAgent;
