import React, { useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { MdLunchDining, MdOutlinePeopleOutline } from 'react-icons/md';
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';
import { FiAlignRight } from 'react-icons/fi';
import { IoMdTime, IoMdCloseCircle } from 'react-icons/io';
import { Mealcontext } from '../../../../context/MealContext';
import MealDelete from '../utils/MealDeleteHandler';
import DeleteConfirmationModal from './DeleteModal';
import AddEditMeal from './AddEditMeal';

const MealCard = ({ 
  id, 
  mealname, 
  restaurantid,
  description, 
  type, 
  price,
  finalprice, 
  format, 
  image, 
  isVeg,
  mealOffereds,
  cuisine,
}) => {
  const [mealOfferd, setMealOffered] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteModalShow, setDeleteModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const { 
    setRefresh, 
    meals, 
    setMeals, 
    setIsDetailsClick, 
    setRestaurantDetailsId,
    isDetailsClick,
   } = useContext(Mealcontext);
  const [restaurantName, seRestaurantName] = useState('');
  const [starttime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [settingCapacity, setSettingCapacity] = useState('');
  const [cappingCapacity, setCappingCapacity] = useState('');
  const handleDeleteModalShow = () => setDeleteModal(true);
  const handleDeleteModalClose = () => setDeleteModal(false);

  let label;

  if (isVeg === 1) {
    label = <span className="bg-success text-white p-2 rounded">VEG</span>;
  } else if (isVeg === 2) {
    label = <span className="bg-danger text-white p-2 rounded w-50">NON-VEG</span>;
  } else {
    label = <span className="bg-warning text-white p-2 rounded w-50">VEG / NON-VEG</span>;
  }

  
  const fetchrestaurantName = async () => {
    try {
      const url = await fetch(`https://navrang.mvd-tech.io/API/restaurant_data_fetch.php?id=${restaurantid}`);
      const response = await url.json();
      if (response.status && response.message === 'Success') {
        seRestaurantName(response?.data[0]?.name);
        setStartTime(response?.data[0]?.start_time);
        setEndTime(response?.data[0]?.end_time);
        setSettingCapacity(response?.data[0]?.setting_capacity);
        setCappingCapacity(response?.data[0]?.capping_capacity);
      }
    } catch (error) {
      toast.error('Failed to fetch restaurant name');
      // console.error(error.message);
    }
  };

  useEffect(() => {
    fetchrestaurantName();
  }, []); // Fetch restaurant name only on initial load

  useEffect(() => {
    if (mealOffereds) {
      const mealOffer = mealOffereds.split(','); 

      const mappedMeals = mealOffer.map((meal) => {
        switch (meal) {
          case '1':
            return 'Breakfast';
          case '2':
            return 'Snacks';
          case '3':
            return 'Lunch';
          case '4':
            return 'Dinner';
          default:
            return ''; 
        }
      });

      setMealOffered(mappedMeals); 
    }
  }, [mealOffereds]); 

  // Edit Meal Data Handler
  const EditMealData = async (values) => {
    const formattedValues = {
      items: meals,
  };
    const sdate = new Date(values.start_date);
    const startDate = sdate.toISOString().split('T')[0];
    const edate = new Date(values.end_date);
    const endDate = edate.toISOString().split('T')[0];

    const mealOffered = [];

    if (values.breakfast) {
        mealOffered.push('1');
    }
    if (values.snacks) {
        mealOffered.push('2');
    }
    if (values.lunch) {
        mealOffered.push('3');
    }
    if (values.dinner) {
        mealOffered.push('4');
    }

    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('name', values.name); 
    formData.append('restaurant_id', values.restaurant_id.value); 
    formData.append('price', values.price); 
    formData.append('foc', values.foc); 
    formData.append('foc_on', values.foc_on); 
    formData.append('meal_type', values.meal_type); 
    formData.append('discription', values.description); 
     formData.append('photo', values.logo); 
    formData.append('start_date', startDate); 
    formData.append('end_date', endDate); 
    formData.append('markup_percentage', values.markup_percentage);
    formData.append('cuisine', values.cuisines); 
    formData.append('defaultMarkup', values.markup_percentage); 
    formData.append('meal_offered', mealOffered.join(',')); 
    formData.append('meal_format', values.format); 
    formData.append('veg_non_veg', values.veg); 
    
    if (Array.isArray(formattedValues.items)) {
      formData.append('items', JSON.stringify(formattedValues)); 
  } else {
      console.error('Items format is invalid');
  }

    //   formData.forEach((value, key) => {
    //     console.log(`${key}: ${value}`);
    // });

    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/meal_edit.php', {
          method: 'POST',
          body: formData,
      });
      if (!response.ok) {
          console.log(`Network response was not ok: ${response.statusText}`);
      }
      const responseData = await response.json();
      if (responseData.status && responseData.message === 'Data updated successfully.') {
          toast.success('Meal Edit Successfully...');
          setRefresh(prev => !prev);
          setShowModal(false);
          setMeals([]);
      } else {
          toast.error(responseData.message);
      }
  } catch (error) {
      toast.error(error.message);
  }   
  };
  // image source 
  const logo = `https://navrang.mvd-tech.io/API/images/meal/${image}`;
 const MealDetails = () => {
  setIsDetailsClick(true);
  setRefresh(prev => !prev);
  // console.log(`mealDetailsClick${isDetailsClick}`);
  setRestaurantDetailsId(id);
 };


  return (
    <div className="card mb-3 position-relative" style={{ maxWidth: '100%' }}>
      <div className="row">
        <div className="col-md-4 col-12">
          <img 
            src={logo} 
            alt={mealname} 
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover',
              marginTop: '12px',
              marginBottom: '12px',
              borderRadius: '10px',
            }} 
          />
        </div>

        <div className="col-md-4 col-12">
          <div className="card-body">
            <h3 className="card-text" style={{ color: 'black' }}>
              {mealname }  {restaurantName ? `/${restaurantName}` : '' }
            </h3>

            <p className="card-text"><FiAlignRight /> {description}</p>
            <p className="card-text">
              <IoMdTime /> Timing: {starttime} - {endTime}
            </p>
            <p className="card-text">
              Type : {type}
            </p>
            <p className="card-text">
              Format: {
                (() => {
                  switch (format) {
                    case '1':
                      return 'FIT';
                    case '2':
                      return 'GIT';
                    default:
                      return 'Delhivery';
                  }
                })()
              }
            </p>
            <p className="card-text">
              Meal Offered: {
                 mealOfferd.join(',')
              }
            </p>

          </div>
        </div>

        <div className="col-md-4 col-12">
          <div className="card-body">
            {label}
            <p className="card-text mt-2"><MdLunchDining /> Cuisine: {cuisine}</p>
            <p className="card-text"><MdOutlinePeopleOutline /> Capacity: {settingCapacity} / {cappingCapacity}</p>
          </div>
          <div className="card-body price d-flex align-items-center">
            <h2 className="card-text me-2" style={{ color: 'red' }}>
              {price} <p className="d-inline" style={{ fontSize: '0.75em' }}>AED</p>
            </h2>
            <h2 className="card-text me-2" style={{ color: 'blue' }}>
              {finalprice} <p className="d-inline" style={{ fontSize: '0.75em' }}>AED</p>
            </h2>
          </div>
          <ButtonGroup icons dir="ltr" className="mt-2 mx-2">
            <Button variant="outline-success" title="Edit" onClick={toggleModal}>
              <span className="lnr lnr-pencil" /> Edit          
            </Button>
            <Button variant="outline-danger" title="Delete" onClick={handleDeleteModalShow}>
              <span className="lnr lnr-trash" /> Delete
            </Button>
            <Button variant="outline-primary" title="Details" onClick={() => MealDetails()}>
              <span className="lnr lnr-file-empty" /> Details
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <DeleteConfirmationModal
        show={deleteModalShow}
        handleClose={handleDeleteModalClose}
        handleDelete={() => MealDelete(id, setRefresh)}
        message="Are you sure you want to delete this meal?"
      />
      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="exampleModalLabel">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">Add/Edit</h3>
                <Button type="button" variant="primary" rounded size="sm" onClick={toggleModal} aria-label="Close">
                  <IoMdCloseCircle className="close-icon" />
                </Button>
              </div>
              <div className="modal-body">
                <AddEditMeal onSubmit={EditMealData} id={id} />
              </div>
              {/* <div className="modal-footer">
                <Button type="button" variant="danger" rounded onClick={toggleModal}>
                  Close
                </Button>
                <Button type="button" variant="success" rounded>
                  Save changes
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MealCard.propTypes = {
  id: PropTypes.string.isRequired,
  mealname: PropTypes.string.isRequired,
  restaurantid: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  finalprice: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isVeg: PropTypes.string.isRequired,
  mealOffereds: PropTypes.string.isRequired,
  cuisine: PropTypes.string.isRequired,
};

export default MealCard;
